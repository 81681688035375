@font-face {
	font-family: 'Gotham Light Regular';
    src: url('Gotham-Light-Regular.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'Gotham-Bold';
    src: url('Gotham-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
	font-family: 'Gotham-Book';
    src: url('Gotham-Book.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}