.main-wrapper-animation {
    display: flex;
    width: 100%;
    background-color: #14353d;
    height: 100vh;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
}

.eclipse {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:300px;
    height:300px;
}
.eclipse .sun {
    position:absolute;
    width: 250px;
    height: 250px;
    background: url('https://magellantv-smart-tv-assets.s3.amazonaws.com/images/home-bg.jpg') 70% 20%;
    border-radius:50%;
}
.eclipse .moon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 240px;
    height: 240px;
    background:#14353d;
    border-radius:50%;
    margin:-145px;
    animation: moveMoon 2000ms linear infinite;
    animation-direction: alternate;
}
@keyframes moveMoon {
    0% {
        transform: translateX(-200px);
    }
    50% {
        transform: skew();
    }
    100% {
        transform: translateX(200px);
    }
}