.wrapper-paywall {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: url('https://magellantv-smart-tv-assets.s3.amazonaws.com/images/paywall-bg.jpg') center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
}

.wrapper-logo {
    margin-left: 200px;
}

.banner-text {
    width: 403px;
    font-family: 'Gotham Light Regular';
    font-size: 30px;
    color: #34cdfe;
    text-align: center;
    text-align: justify;
    position: relative;
    left: 98px;
    margin-bottom: 25px;
}

.banner-subtitle {
    font-family: 'Gotham Light Regular';
    font-size: 22px;
    line-height: 34px;
    color: #fff;
    width: 640px;
    position: relative;
    left: 200px;
    bottom: 30px;    
}

.wrapper-button {
    position: relative;
    left: 200px;
}

.button-box {
    border: none;
    border-radius: 6px;
    font-size: 22px;
    height: 66px;
    margin-bottom: 10px;    
    width: 320px;
    font-family: 'Gotham Light Regular';
    cursor: pointer;
    background-color: #fff;
}

.color-button {
    color: #fff;
    background-color: #34cdfe;    
}