.wrapper-exit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #101012;
    width: 100%;
    height: 100vh;
    position: relative;
}

.wrapper-title {
    margin-bottom: 46px;
}

.title-style {
    font-family: 'Gotham Light Regular';
    font-size: 56px;
    color: #fff;
}

.wrapper-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.button-style {
    background: hsla(0,0%,100%,.15);
    color: #fff;
    width: 400px;
    margin-bottom: 32px;
    text-transform: uppercase;
    border: none;
    border-radius: 6px;
    height: 96px;
    margin-left: 50px;
    cursor: pointer;
}

.active-button {
    color: #fff;
    background-color: #34cdfe;   
}