.player-wrapper {
    position: relative;
}
   
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

video::-webkit-media-controls-volume-slider-container {
    display: none;
}

video::-webkit-media-controls-volume-slider {
    display: none;
}

video::-webkit-media-controls-fullscreen-button {
    display: none;
}

video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}