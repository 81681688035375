.flex-wrapper {
    position: relative;
    display: flex;
    height: 113px;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    z-index: 9999;
}

.browse-header { 
    display: flex;
    width: 100%;
    height: 65px;
    background: linear-gradient(to bottom, rgba(52, 203, 254, 1) 0%, rgba(26, 102, 127, 1) 100%);
    background-color: #34cdfe;
    justify-content: center;
    align-items: center;
}

.list-container {
    display: flex;
    flex-direction: row;
    padding-left: 45rem;
    list-style: none;
    font-size: 1.2rem;
    margin-left: 8px;
    font-family: 'SemplicitaPro-Medium';
    letter-spacing: 2.3px;
    text-align: center;
}

.item-space {
    text-decoration: none;
}

.link-no-decoration-login {
    text-decoration: none;
    color: #fff;
}

.button-border {
    border: 1px solid #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 30px;
    text-decoration: none;
}

.link-no-decoration-star-free{
    text-decoration: none;
    color: #fff;    
}

.image-magellan-logo {
    display: fixed;
    padding-left: 100px;
}

.animation-in {
    position: relative;
    top: 0px;
    transition: all 0.3s linear;
}

.animation-out {
    position: relative;
    top: -100px;
    transition: all 0.3s linear;
}

.wrapper-nav {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 160px;
    background: linear-gradient(rgba(0,0,0,.6),70%,transparent);
}