.main-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
}

.wrapper-explore::-webkit-scrollbar {
    width: 0 !important 
}

.wrapper-explore-carousels{
    display: flex;
    flex-direction: column;
    width: 7000px;
}