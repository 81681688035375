.wrapper-settings { 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.inner-settings {
    display: flex;
    flex-direction: row;
    height: 500px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;
    height: auto;
    position: relative;
    left: 80px;
    bottom: 60px;
}

.contact-magellan {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 18px;
    line-height: 1.29;
    position: fixed;
    text-align: center;
    width: 510px;
}

.copyright {
    color: hsla(0,0%,100%,.6);
    font-size: 16px;
    line-height: 1.4;
    position: relative;
    font-family: 'Gotham Light Regular';
    left: 10%;
    top: 70px; 
}
