.main-wrapper-body {
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: fixed;
}

.main-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.overlay {
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    background-size: cover;   
}

.bg-overlay-dark {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-overlay-light{
    background-color: rgba(0, 0, 0, 0.4);
}

.height-loader{
    height: 100vh !important;
}

.normal-height{
    height: auto !important;
}
 