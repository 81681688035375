.vewdPlayerWrapper {
    width: 100vw;
    height: 100vh;
}

.vewdPlayerWrapper video {
    width: 100vw;
    height: 100vh;
}

.notSupported {
    background: #000;
    z-index: 99999;
    color: #FFF;
    position: absolute;
    top: 20%;
    left: 50%;
    margin-left: -300px;
    width: 500px;
    padding: 50px;
    font-size: 30px;
    font-family: 'Gotham Light Regular';
    line-height: 34px;
}