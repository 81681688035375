.wrapper-item-slider {
    display: flex;
    align-items: center;
    border-radius: 1%;
    width: 1050px;
    height: 430px;
    z-index: 1;
    position: relative;
    z-index: 1;
    margin-right: 30px;
    margin-left: 40px;
    transition: transform 0.25s ease-in-out;
}

.select-big-slider {
    z-index: 2;
    transform: scale(1.05, 1.05);    
}

.slider-title {
    font-family: 'Gotham Light Regular';
    font-size: 80px;
    color: #fff;
    text-transform: uppercase;
    padding-left: 4%;
}