.main-wrapper {
    display: flex;
    flex-direction: column;
    flex-flow: column;
    width: 100%;
    margin-left: 36px;
    z-index: 1;
}

.wrapper-slider{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    top: 40px;
}

.wrapper-image-title {
    display: flex;
    width: 60000px;
    height: 250px;
    position: relative;
    margin-bottom: 100px;
    margin-left: 60px;
}

.watch-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 66px;
    color: black;
    background-color: #fff; 
    font-family: 'Gotham Light Regular';
    cursor: pointer;
    border-radius: 6px;
    font-size: 24px;
    position: relative;
    left: 70px;
    margin-bottom: 100px;
}

.watch-button-active {
    color: #fff;
    background-color: #34cdfe;     
}