.wrapper-device {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: url('https://magellantv-smart-tv-assets.s3.amazonaws.com/images/try-free-bg.jpg') center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
}

.preview-title{
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 24px;
    align-self: center;
    font-weight: 600;
    position: relative;
    bottom: 100px;
}

.main-logo-box {
    background: url('https://magellantv-smart-tv-assets.s3.amazonaws.com/images/try-free-devices.png') center center no-repeat;
    background-size: 100% 90%;
    width: 430px;
    height: 280px;
}

.wrapper-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 90px;
    position: relative;
    bottom: 80px;
}

.logo-magellan{ 
    background: url('https://magellantv-smart-tv-assets.s3.amazonaws.com/images/client-logo.png') center center no-repeat;
    background-size: 68% 37%;
    width: 400px;
    height: 400px;
}

.title-tryfree{
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 40px;
    align-self: center;
    font-weight: 600;
    position: relative;
    bottom: 60px;
}

.from-pc-title {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 28px;
    opacity: .75;
    position: relative;
    bottom: 35px;
    align-self: flex-start;
}

.link-magellan {
    color: #33ccfd;
    font-family: 'Gotham Light Regular';
    font-size: 28px;
    margin-top: 24px;
    position: relative;
    bottom: 35px;
}

.code-title {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 28px;
    align-self: flex-start;
    font-weight: 400;
}

.code-obtained {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 34px;
    font-weight: 600;
    padding-top: 1rem;
    align-self: flex-start;
}

.button-keep-browsing{
    border: none;
    border-radius: 6px;
    font-size: 22px;
    font-weight: 400;
    height: 76px;
    margin-bottom: 10px;    
    width: 285px;
    font-family: 'Gotham Light Regular';
    cursor: pointer;
    background-color: #34cdfe;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 25px;
    right: 120px;
}