.wrapper-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form-container {
    display: flex;
    width: 800px;
    height: 55px;
    background-color: white;
    margin-bottom: 5%;
}

.input-style{
    width: 570px;
    outline: none;
    font-size: 1.8rem;
    border: none;
    padding-top: 10px;
    padding-left: 14px;
    caret-color: #34cdfe;
}

.no-results-title{
    font-size: 2.5rem;
    line-height: 1.28;
    letter-spacing: 0.3rem;
    margin-bottom: -8px;
    color: #34cdfe;
    font-family: 'Gotham Light Regular';
    text-transform: uppercase;
}

.initial-search-title{
    font-size: 35px;
    font-weight: 500;
    line-height: 1.42;
    color: #ccc;
    justify-content: center;
    max-width: 550px;
    font-family: 'Gotham Light Regular';
}

.wrapper-search-carousel .wrapper-carousel {
    width: auto;
}
