.dot {
    background: hsla(0,0%,100%,.5);
    cursor: pointer;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-left: 10px;
}

.active-dot {
    background: #34cdfe;
    margin-left: 10px;
}