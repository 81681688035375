.wrapper-device-signin {
    background: linear-gradient(0deg,#091926,#14353d);
    width: 100%;
    height: 100vh;
}

.wrapper-panel-buttons {
    display: flex;
    flex-direction: column;
    position: relative;
    left: 20px;
    top: 60px;
}

.container-form-code {
    display: flex;
    position: relative;
    left: 100px;
    top: 60px;
}

.link-account {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 40px;
    line-height: 68px;
    opacity: .5;
    padding: 5rem 6rem; 
}

.device-link-subtitle {
    font-family: 'Gotham Light Regular';
    font-size: 22px;
    color: #fff;
    position: relative;
    bottom: 50px;
    left: 100px;
    letter-spacing: 0.4px;
}

.device-link-samsung {
    font-family: 'Gotham Light Regular';
    font-size: 28px;
    color: #fff;
    position: relative;
    bottom: 35px;
    left: 100px;
    font-weight: 600;    
}

.device-description {
    font-family: 'Gotham Light Regular';
    font-size: 22px;
    line-height: 54px;
    position: relative;
    left: 100px;
    margin-top: 5px;
    width: 820px;
    color: #fff;
    letter-spacing: 0.4px;
}

.linking-code {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 28px;
    line-height: 68px;
    opacity: .5;    
}

.code-line-text {
    font-size: 55px;
    color: #fff;
    font-family: 'Gotham-Bold';
    position: relative;
    bottom: 10px;
}

.divide-line {
    border-bottom: 2px solid #fff;
    width: 600px;
}

.update-successful {
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 22px;
    line-height: 34px;
    opacity: .5;
    width: auto;
    position: relative;
    top: 20px;    
}

.button-wrapper{
    border: none;
    border-radius: 6px;
    font-size: 22px;
    height: 76px;
    margin-bottom: 10px;    
    width: 285px;
    font-family: 'Gotham Light Regular';
    cursor: pointer;
    background-color: #fff;
    color: #091926;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 25px;
}

.color-hover-button {
    color: #fff;
    background-color: #34cdfe;
}