.wrapper-account{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 35px;
    left: 60px;
}

.settings_title {
    font-family: 'Gotham-Bold';
    font-size: 48px;
    color: #fff;
}

.settings_content_item {
    display: block;
    margin-top: 30px;
    opacity: .6;
    text-align: left;
    font-family: 'Gotham-Book';
    color: #fff;
    font-size: 20px;        
}

.button-settings{
    background: transparent;
    border: none;
    border-radius: 6px;
    color: #fff;
    display: block;
    font-family: 'Gotham Light Regular';
    font-size: 36px;
    height: 66px;
    padding-top: 6px;
    width: 510px;
    background: hsla(0,0%,100%,0.1);
    position: relative;
    top: 30px;
    cursor: pointer;
}

.active-button-login {
    background: #33ccfd;
}

.moving-up{
    position: relative;
    bottom: 25px;
}

.moving-up-terms {
    position: relative;
    bottom: 45px;
}

.moving-up-about {
    position: relative;
    bottom: 65px;
}