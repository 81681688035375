.container-item-carousel {
    display: flex;
    width: 249px;
    height: 159px;
    border-radius: 2%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom:45px;
    margin-left: 30px;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: transform 0.25s ease-in-out; 
}

.select-item-carousel{
    z-index: 2;
    transform: scale(1.16, 1.16);
}

.duration-label{
    background: #000;
    color: #fff;
    height: 26px;
    width: auto;
    position: relative;
    top: 25px;
    left: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.title-name {
    position: relative;
    top: 2px;
    font-family: 'semplicitapro';
}

.featured-tagline{
    color: #fff;
    font-family: 'semplicitapro';
    font-size: 36px;
    line-height: 42px;
}