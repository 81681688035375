.container-carousel {
    display: flex;
    flex-direction: column;
    width: auto;
    position: relative;
    top: 35px;
    left: 40px;
}

.envelope-button-item {
    position: relative;
    top: 15%;
}

.wrapper-carousel{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 20000px;
    margin-top: 35px;
}

.documentarie-title {
    font-family: 'Gotham-Book';
    font-size: 28px;
    text-transform: uppercase;
    color: #fff;
    margin-left: 36px;
    position: relative;
    top: 35px;
}