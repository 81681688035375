.navbar-wrapper {
    width: 55%;
    height: 168px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Gotham-Book';
    font-weight: 550;
    position: relative;
    left: 200px;
    top: 20px;
}

.navbar-wrapper,
.navbar-wrapper > div {
    outline: none !important;
}

.default-wrapper{
    background-color: transparent;
    padding-left: 17px;
    padding-right: 17px;
}

.default-wrapper-tryfree{
    background-color: transparent;
    height: 40px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid #fff;
    border-radius: 3px;  
}

.icon-dropdown {
    color: #34CDFE;
    font-size: 1.3rem;
    line-height: 1.6rem;
}

.bg-white {
    color: #fff;
}

.hover-background {
    widows: auto;
    height: 50px;
    border-radius: 3px;
    background: #33ccfd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 17px;
    padding-right: 17px;
}

.active-tab {
    border-bottom: .2rem solid #34CDFE;
}

.cls-1{
    fill: #fff;
}

.svg-position{
    position: relative;
    top: 3px;
}