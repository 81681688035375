.container-detail{
    position: relative;
    top: 40px;
}

.wrapper-item-detail{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 82px;
}

.item-detail-title {
    font-family: 'Gotham Light Regular';
    font-size: 48px;
    line-height: 67px;
    color: #fff;
}

.item-detail-duration {
    font-size: 24px;
    opacity: .4;
    font-family: 'Gotham Light Regular';
    color: #f6f5ff;
}

.wrapper-item-description {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1000px;
    padding-top: 30px;
}

.item-detail-description{
    font-size: 24px;
    height: 108px;
    line-height: 36px;
    opacity: .9;
    color: #f6f5ff;
    font-family: 'Gotham Light Regular';
}

.wrapper-button-item-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 66px;
    color: black;
    background-color: #fff;
    font-family: 'Gotham Light Regular';
    cursor: pointer;
    border-radius: 6px;
    font-size: 24px;
    position: relative;
    bottom: 30px;
}

.active-button-detail{
    color: #fff;
    background-color: #34cdfe;
}

.inner-carousel-episodes {
    position: relative;
    top: 215px;
    right: 34px;
    margin-bottom: 80px;
}

.envelope-button-item {
    position: relative;
    top: 20%;
}

.hidden-button-detail{
    display: none;
}