.wrapper-sidemenu{
    display: flex;
    flex-direction: column;
    height: 561px;
    padding-left: 40px;
    position: relative;
    top: 20px;
    left: 20px;
}

.wrapper-sidemenu-settings {
    display: flex;
    flex-direction: column;
    height: 300px;
    padding-left: 100px;
    position: relative;
    top: 6%;     
}

.font-menu-style {
    border-radius: 6px;
    color: #fff;
    font-family: 'Gotham Light Regular';
    font-size: 24px;
    text-transform: uppercase;
    cursor: pointer;
    padding-bottom: 18px;
    height: 60px;
    width: 380px;
}

/* Just for using the mouse */
.active-side-tab{
    background: #33ccfd;
    color: #fff;
    height: 60px;
    width: 340px;    
}

.active-elm {
    border-left: 3px solid #33ccfd;
    border-radius: 0px;
    height: 50px;
    position: relative;
    right: 3px;
}