.wrapper-content {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    width: 100%;
    height: calc(100% - 150px);
}

.carousel-items-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.carousel-items-menu::-webkit-scrollbar{
    display: none;
}